import * as React from 'react';
import { CalendarBlank } from '@phosphor-icons/react';
import format from 'date-fns/format';

import { Calendar } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { cn } from './utils/cn';

export type DatePickerProps = {
  align?: React.ComponentProps<typeof PopoverContent>['align'];
} & React.ComponentProps<typeof Calendar>;

export const DatePicker = ({ align, ...props }: DatePickerProps) => {
  let date: Date | undefined;

  if (props.mode === 'single') {
    date = props.selected;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button
          className={cn(
            'items-center transition-colors flex h-11 w-full color-ink rounded-md border border-input bg-transparent px-3 py-2 bits-text-body-1 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-smoke focus-visible:outline-none focus-visible:border-ink disabled:cursor-not-allowed disabled:bg-fog disabled:text-smoke',
            !date && 'text-muted-foreground'
          )}
        >
          {date ? format(date, 'PPP') : <span>Pick a date</span>}
          <CalendarBlank className="ml-auto h-4 w-4" />
        </button>
      </PopoverTrigger>
      <PopoverContent align={align} className="w-auto p-0">
        <Calendar {...props} />
      </PopoverContent>
    </Popover>
  );
};
