'use client';

import * as React from 'react';
import { Check } from '@phosphor-icons/react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '../utils/cn';

export interface ThemedCheckboxProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    'label'
  > {
  label: string;
  error?: string;
}

export const ThemedCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  ThemedCheckboxProps
>(({ className, label, error, ...props }, ref) => (
  <label
    className={cn(
      'flex gap-2 items-start cursor-pointer',
      props.disabled && 'cursor-not-allowed opacity-50'
    )}
  >
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'relative peer [width:calc(20px+var(--inputs-borderWidth))] [height:calc(20px+var(--inputs-borderWidth))] shrink-0 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        '[border-color:var(--inputs-normal-borderColor)] [border-width:var(--inputs-borderWidth)] [border-radius:min(5px,var(--inputs-cornerRadius))] [box-shadow:var(--inputs-shadow)]',
        error && '[border-color:var(--inputs-error-borderColor)]',
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn(
          '[color:var(--inputs-normal-textColor)] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 '
        )}
      >
        <Check className="h-4 w-4" weight="bold" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    <p
      className={cn(
        '[font-size:var(--inputs-labelFontSize)]',
        error && '[color:var(--inputs-errorTextColor)]'
      )}
    >
      {label}
    </p>
  </label>
));
ThemedCheckbox.displayName = CheckboxPrimitive.Root.displayName;
