import React, { FormEvent } from 'react';
import { SwitchProps } from '@radix-ui/react-switch';

import { Label } from '../label';
import { Switch } from '../switch';
import { InputErrorAndHint } from './input-error-and-hint';

export const FormSwitch = ({
  label,
  error,
  hint,
  value,
  ...props
}: {
  label: string;
  error?: string;
  hint?: string;
  value: boolean;
} & Omit<SwitchProps, 'onChange' | 'value'> & {
    onChange?: (e: {
      target: {
        name?: string;
        value: boolean;
      };
    }) => void;
  }) => {
  return (
    <div>
      <Label className="flex justify-between items-center cursor-pointer">
        <p className="bits-text-body-2">{label}</p>
        <Switch
          {...props}
          onChange={undefined}
          defaultChecked={value}
          onCheckedChange={(value) => {
            // Make switch work with react-hook-form
            props.onChange?.({
              target: {
                name: props.name,
                value,
              },
            });
          }}
        />
      </Label>
      <InputErrorAndHint error={error} hint={hint} />
    </div>
  );
};
