import React, { HTMLProps, useMemo } from 'react';
import {
  CustomerThemeInputSchema,
  objectToCustomerTheme,
} from '@bits/customer-theme';

import { cn } from '../utils/cn';

export const CustomerThemeProvider = ({
  theme = {},
  children,
  ...props
}: HTMLProps<HTMLDivElement> & {
  theme?: CustomerThemeInputSchema;
}) => {
  const { cssVariables, customerTheme } = useMemo(
    () => objectToCustomerTheme(theme || {}),
    [theme]
  );

  return (
    <div
      style={{
        fontFamily: '"bits-theme-font", "Aktiv Grotesk", sans-serif',
        ...cssVariables,
      }}
      className={cn(
        '[color:var(--typography-paragraph-textColor)]',
        props.className
      )}
      {...props}
    >
      <style>{`
        ${
          customerTheme.general.fontUrls.regular
            ? `@font-face { 
                font-family: 'bits-theme-font'; 
                font-weight: normal;
                src: url('${customerTheme.general.fontUrls.regular}'); 
              }`
            : ''
        }
        ${
          customerTheme.general.fontUrls.bold
            ? `@font-face { 
                font-family: 'bits-theme-font'; 
                font-weight: bold;
                src: url('${customerTheme.general.fontUrls.bold}'); 
              }`
            : ''
        }
      `}</style>

      {children}
    </div>
  );
};
