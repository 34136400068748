import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';

import { cn } from '../utils/cn';

export interface ThemedButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  children: ReactNode;
}

export const ThemedButton = React.forwardRef<
  HTMLButtonElement,
  ThemedButtonProps
>(({ asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return (
    <Comp
      className={cn(
        'transition-all duration-75 p-6 py-3 bg-[var(--buttons-primary-normal-backgroundColor)]  [letter-spacing:var(--buttons-primary-letterSpacing)] [text-transform:var(--buttons-primary-uppercase)] [box-shadow:var(--buttons-primary-shadow)] [font-weight:var(--buttons-primary-fontWeight)] [font-size:var(--buttons-primary-fontSize)] [color:var(--buttons-primary-normal-textColor)] rounded-[var(--buttons-primary-cornerRadius)] [border-width:var(--buttons-primary-borderWidth)] [border-color:var(--buttons-primary-normal-borderColor)]',
        'hover:bg-[var(--buttons-primary-hover-backgroundColor)] hover:[color:var(--buttons-primary-hover-textColor)] hover:border-[var(--buttons-primary-hover-borderColor)]',
        'active:bg-[color-mix(in_srgb,var(--buttons-primary-hover-backgroundColor)_90%,black)] active:duration-0',
        'disabled:opacity-50 disabled:pointer-events-none',
        props.className
      )}
      {...props}
      ref={ref}
    />
  );
});
ThemedButton.displayName = 'ThemedButton';
