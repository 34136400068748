import React, { useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';

import { Label } from '../label';
import { InputErrorAndHint } from './input-error-and-hint';

export interface FormSelectProps {
  label: string;
  error?: string;
  hint?: string;
  value?: string;
  onChange?: (e: { target: { name?: string; value: string } }) => void;
  options: {
    value: string;
    label: string;
  }[];
}

export const FormSelect = ({
  label,
  options,
  error,
  hint,
  value,
  onChange,
}: FormSelectProps) => {
  const [displayValue, setDisplayValue] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDisplayValue(e.target.value);
    onChange?.({
      target: {
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  return (
    <div>
      <Label>
        <p className="bits-text-body-2 mb-1">{label}</p>

        <div className="relative">
          <select
            onChange={handleChange}
            className="opacity-0 w-full h-full absolute top-0 left-0"
          >
            {options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                selected={value === option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
          <div
            aria-hidden
            className="border border-fog rounded-lg h-11 p-2 px-3 pr-6 items-center bits-text-body-1 overflow-hidden min-w-0 text-ellipsis text-nowrap"
          >
            {options.find((option) => option.value === displayValue)?.label}
          </div>
          <CaretDown
            aria-hidden
            className="absolute pointer-events-none right-2 top-1/2 -translate-y-1/2"
          />
        </div>
      </Label>
      <InputErrorAndHint error={error} hint={hint} />
    </div>
  );
};
