'use client';

import * as React from 'react';
import { Circle } from '@phosphor-icons/react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '../utils/cn';

export interface ThemedRadioGroupProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {}

export const ThemedRadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  ThemedRadioGroupProps
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-3', className)}
      {...props}
      ref={ref}
    />
  );
});
ThemedRadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export interface ThemedRadioGroupItemProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>,
    'label'
  > {
  label: string;
  error?: string;
}

export const ThemedRadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  ThemedRadioGroupItemProps
>(({ className, label, error, ...props }, ref) => {
  return (
    <label
      className={cn(
        'flex gap-2',
        props.disabled && 'cursor-not-allowed opacity-50'
      )}
    >
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          'aspect-square shrink-0 [width:calc(20px+var(--inputs-borderWidth))] [height:calc(20px+var(--inputs-borderWidth))] rounded-full borde ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          '[border-color:var(--inputs-normal-borderColor)] [border-width:var(--inputs-borderWidth)] [box-shadow:var(--inputs-shadow)]',
          error && '[border-color:var(--inputs-error-borderColor)]',
          className
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
          <Circle
            weight="fill"
            className="h-3 w-3 fill-current [color:var(--inputs-normal-textColor)]"
          />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
      <p
        className={cn(
          '[font-size:var(--inputs-labelFontSize)]',
          error && '[color:var(--inputs-errorTextColor)]'
        )}
      >
        {label}
      </p>
    </label>
  );
});
ThemedRadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;
