import React, { forwardRef } from 'react';

import { Input, InputProps } from '../input';
import { Label } from '../label';
import { useForwardedRef } from '../utils/use-forwarded-ref';
import { InputErrorAndHint } from './input-error-and-hint';

export const FormColorInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'onChange'> & {
    label: string;
    hint?: string;
    error?: string;
    onChange?: (e: {
      target: {
        name?: string;
        value: string;
      };
    }) => void;
  }
>(({ label, hint, error, value, ...props }, ref) => {
  const colorInput = React.createRef<HTMLInputElement>();
  const textInput = useForwardedRef(ref);
  const preview = React.createRef<HTMLSpanElement>();

  const handleChangeColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      props.onChange?.({
        target: {
          name: props.name as string,
          value: e.target.value,
        },
      });

      if (colorInput.current) colorInput.current.value = e.target.value;
      if (textInput.current) textInput.current.value = e.target.value;
      if (preview.current)
        preview.current.style.backgroundColor = e.target.value;
    }
  };

  return (
    <div>
      <Label>
        <p className="bits-text-body-2 mb-1">{label}</p>
        <div className="relative">
          <Input
            aria-hidden
            ref={textInput}
            {...props}
            defaultValue={value}
            onChange={handleChangeColor}
            onBlur={(e) => {
              if (!e.target.value.includes('#')) {
                e.target.value = `#${e.target.value}`;
                handleChangeColor(e as any);
              }
            }}
          />
          <span
            ref={preview}
            aria-hidden
            className="absolute top-1/2 right-2 -translate-y-1/2 h-5 w-5 rounded-sm border border-fog"
            style={{ backgroundColor: value as string }}
          >
            <input
              className="w-full h-full opacity-0 absolute"
              ref={colorInput}
              type="color"
              defaultValue={value}
              onChange={handleChangeColor}
            />
          </span>
        </div>
      </Label>
      <InputErrorAndHint error={error} hint={hint} />
    </div>
  );
});
