import React, { ComponentProps, ElementType } from 'react';
import { IconProps } from '@phosphor-icons/react';

import { type Icon } from './icon';
import { cn } from './utils/cn';

export type RoundButtonProps<AType extends ElementType> = {
  as?: AType;
  icon?: Icon;
  iconProps?: IconProps;
  size?: 'sm' | 'md';
};

export const RoundButton = <AType extends ElementType>({
  as,
  icon: Icon,
  iconProps: { className: iconClassName, ...iconProps } = {},
  size = 'md',
  className,
  ...props
}: RoundButtonProps<AType> & Omit<ComponentProps<AType>, 'as' | 'size'>) => {
  const Component = as || 'button';
  return (
    <Component
      className={cn(
        'bits-text-button-1 bg-fog text-primary-foreground inline-flex items-center justify-center rounded-full ring-offset-background transition-colors',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        'disabled:pointer-events-none disabled:opacity-50 hover:bg-smoke',
        size == 'md' && 'w-9 h-9',
        size == 'sm' && 'w-6 h-6',
        className
      )}
      {...props}
    >
      {Icon ? (
        <Icon
          className={cn(
            'fill-ink',
            size == 'md' && 'h-[20px] w-[20px]',
            size == 'sm' && 'h-[16px] w-[16px]',
            iconClassName
          )}
          {...iconProps}
        />
      ) : null}
    </Component>
  );
};
