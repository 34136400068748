import * as React from 'react';
import { Scroll } from '@phosphor-icons/react/dist/ssr';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { cn } from './utils/cn';

type ScrollAreaViewPortProps = React.ComponentPropsWithoutRef<
  typeof ScrollAreaPrimitive.Root
> & {
  ref?: React.RefObject<React.ElementRef<typeof ScrollAreaPrimitive.Root>>;
};

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    viewPortProps?: ScrollAreaViewPortProps;
  }
>(
  (
    {
      className,
      children,
      viewPortProps: {
        className: viewPortClassName = undefined,
        ...viewPortRest
      } = {},
      ...props
    },
    ref
  ) => (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={cn('relative overflow-hidden', className)}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        className={cn('h-full w-full rounded-[inherit]', viewPortClassName)}
        {...viewPortRest}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar />
      <ScrollBar orientation="horizontal" />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  )
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = 'vertical', ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      'flex touch-none select-none transition-colors',
      orientation === 'vertical' &&
        'h-full w-[9px] border-l border-l-transparent p-[1px]',
      orientation === 'horizontal' &&
        'h-[9px] border-t border-t-transparent p-[1px]',
      className
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className="relative z-50 opacity-50 flex-1 rounded-full bg-ink" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

/**
 * A ScrollArea that fades out at the top and bottom when content is hidden.
 */
const ScrollAreaWithFade = ({
  children,
  className,
  backgroundColor,
}: {
  children: React.ReactNode;
  className?: string;
  backgroundColor?: string;
}) => {
  const area = React.useRef<HTMLDivElement>(null);
  const viewport = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const container = area.current;
    const target = viewport.current;
    let resizeObserver: ResizeObserver;

    // We handle these state changes outside of react, for performance
    const handleScroll = () => {
      if (container && target) {
        container.dataset['fadeTop'] =
          target.scrollTop === 0 ? 'false' : 'true';
        container.dataset['fadeBottom'] =
          target.scrollTop + target.clientHeight === target.scrollHeight
            ? 'false'
            : 'true';
      }
    };

    handleScroll();

    if (target) {
      target.addEventListener('scroll', handleScroll);
      resizeObserver = new ResizeObserver(handleScroll);
      resizeObserver.observe(target);
    }

    return () => {
      if (target) {
        target.removeEventListener('scroll', handleScroll);
        resizeObserver.disconnect();
      }
    };
  }, [area, viewport]);

  return (
    <ScrollArea
      ref={area}
      viewPortProps={{
        ref: viewport,
      }}
      className={cn('group relative', className)}
      style={
        {
          '--background-color': backgroundColor ?? 'white',
        } as React.CSSProperties
      }
    >
      {children}

      <div className="pointer-events-none bg-gradient-to-b from-[var(--background-color)] to-transparent transition duration-75 opacity-0 group-data-[fade-top=true]:opacity-100 h-12 w-full absolute top-0 left-0" />
      <div className="pointer-events-none bg-gradient-to-t from-[var(--background-color)] to-transparent transition duration-75 opacity-0 group-data-[fade-bottom=true]:opacity-100 h-12 w-full absolute bottom-0 left-0" />
    </ScrollArea>
  );
};

export { ScrollArea, ScrollAreaWithFade, ScrollBar };
