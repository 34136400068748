import { z } from 'zod';

import defaultColors from '../../tailwind-config/bits-colors.js';

const fontWeight = z.enum(['normal', 'bold']);

export const customerThemeSchema = z.object({
  // General
  general: z
    .object({
      iconColor: z.string().default(defaultColors.ink),
      dividerColor: z.string().default(defaultColors.fog),

      // Logo
      logoUrl: z.string().nullish(),

      // Fonts
      fontUrls: z
        .object({
          regular: z.string().nullish(),
          bold: z.string().nullish(),
        })
        .default({}),
    })
    .default({}),

  // Page
  page: z
    .object({
      backgroundColor: z.string().default(defaultColors['sand-light']),
    })
    .default({}),

  // Canvas
  canvas: z
    .object({
      backgroundColor: z.string().default(defaultColors.white),
      borderColor: z.string().default(defaultColors.ink),
      borderWidth: z.number().default(0),
      cornerRadius: z.number().default(16),
      shadow: z.boolean().default(true),
    })
    .default({}),

  // Buttons
  buttons: z
    .object({
      primary: z
        .object({
          letterSpacing: z.number().default(0),
          fontSize: z.number().default(14),
          borderWidth: z.number().default(0),
          cornerRadius: z.number().default(6),
          fontWeight: fontWeight.default('bold'),

          shadow: z.boolean().default(false),
          uppercase: z.boolean().default(false),

          normal: z
            .object({
              backgroundColor: z
                .string()
                .default(defaultColors['tangerine-light']),
              borderColor: z.string().default(defaultColors.ink),
              textColor: z.string().default(defaultColors.ink),
            })
            .default({}),
          hover: z
            .object({
              backgroundColor: z.string().default(defaultColors['tangerine']),
              borderColor: z.string().default(defaultColors.ink),
              textColor: z.string().default(defaultColors.ink),
            })
            .default({}),
        })
        .default({}),
    })
    .default({}),

  // Typography
  typography: z
    .object({
      title: z
        .object({
          fontSize: z.number().default(22),
          fontWeight: fontWeight.default('normal'),
          letterSpacing: z.number().default(-0.5),
          textColor: z.string().default(defaultColors.ink),
          uppercase: z.boolean().default(false),
        })
        .default({}),
      paragraph: z
        .object({
          fontSize: z.number().default(16),
          fontWeight: fontWeight.default('normal'),
          letterSpacing: z.number().default(0),
          textColor: z.string().default(defaultColors.ink),
          uppercase: z.boolean().default(false),
        })
        .default({}),
    })
    .default({}),

  // Inputs
  inputs: z
    .object({
      backgroundColor: z.string().default(defaultColors.white),
      borderWidth: z.number().default(1),
      cornerRadius: z.number().default(6),
      shadow: z.boolean().default(false),
      fontSize: z.number().default(16),

      normal: z
        .object({
          borderColor: z.string().default(defaultColors.fog),
          textColor: z.string().default(defaultColors.ink),
        })
        .default({}),
      focus: z
        .object({
          borderColor: z.string().default(defaultColors.ink),
          textColor: z.string().default(defaultColors.ink),
        })
        .default({}),
      error: z
        .object({
          borderColor: z.string().default(defaultColors.grapefruit),
          textColor: z.string().default(defaultColors.ink),
        })
        .default({}),

      placeholderColor: z.string().default(defaultColors.shadow),

      labelTextColor: z.string().default(defaultColors.ink),
      labelFontSize: z.number().default(14),

      hintAndErrorFontSize: z.number().default(12),
      errorTextColor: z.string().default(defaultColors.grapefruit),
      hintTextColor: z.string().default(defaultColors.shadow),
    })
    .default({}),
});

export type CustomerTheme = z.infer<typeof customerThemeSchema>;
export type CustomerThemeInputSchema = z.input<typeof customerThemeSchema>;
