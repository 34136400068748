module.exports = {
  branding: 'hsl(var(--color-branding))',
  white: '#FFFFFF',
  ink: '#121212',
  shadow: '#646464',
  smoke: '#C0C0C0',
  fog: '#E9EAEF',
  ash: '#AAAAAA',
  grapefruit: '#FC7051',
  'grapefruit-light': '#F5AF98',
  kiwi: '#64D684',
  'kiwi-light': '#98F5C3',
  tangerine: '#F7AB1B',
  'tangerine-light': '#FFE08F',
  sand: '#F8E6CF',
  'sand-light': '#FEF9F4',
  khaki: '#E3D2B8',
  'khaki-light': '#F9F1EA',
};
