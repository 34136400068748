import React, { forwardRef } from 'react';

import { Input, InputProps } from '../input';
import { Label } from '../label';
import { InputErrorAndHint } from './input-error-and-hint';

interface TextInputProps extends InputProps {
  label: string;
  error?: string;
  hint?: string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, hint, error, ...props }: TextInputProps, ref) => {
    return (
      <div>
        <Label>
          <p className="bits-text-body-2 mb-1">{label}</p>
          <Input {...props} ref={ref} />
        </Label>
        <InputErrorAndHint error={error} hint={hint} />
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
