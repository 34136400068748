import React, { ChangeEvent, useRef, useState } from 'react';
import {
  ArrowsClockwise,
  File,
  TrashSimple,
  UploadSimple,
} from '@phosphor-icons/react';

import { SmallButton } from '../small-button';
import { SmallSpinner } from '../small-spinner';
import { cn } from '../utils/cn';

export interface FormFileInputProps {
  value?: string;
  description: string;
  maxSize?: number;
  accept?: string;
  onChange?: (file: File | null) => void | Promise<unknown>;
  onRemove?: () => void;
}

/**
 * File input with preview, if value is provided
 * If the value is an image file, the image will be shown. Otherwise,
 * a file icon will show as a preview.
 */
export const FormFileInput = ({
  value,
  maxSize,
  description,
  accept,
  onChange,
  onRemove,
}: FormFileInputProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [fileError, setFileError] = useState<string | undefined>();
  const [preview, setPreview] = useState<string | undefined>(value);
  const [fileValue, setFileValue] = useState<File | null>();
  const fileInput = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent) => {
    if ('files' in event.target) {
      setFileError(undefined);
      const file = (event.target.files as File[])[0];

      if (file) {
        // Set the preview for the url, it will only show if it's an image
        const previewUrl = URL.createObjectURL(file);
        setPreview(previewUrl);

        setFileValue(file || null);

        // If the file is too big, reset the input value
        if (maxSize && file.size > maxSize && fileInput.current) {
          fileInput.current.value = '';
          setFileError(
            'File exceeds the maximum size of ' + maxSize / 1024 / 1024 + ' MB'
          );
          return;
        }

        const potentialUploadPromise = onChange?.(file || null);

        if (potentialUploadPromise?.then) {
          setIsLoading(true);
          potentialUploadPromise.then(() => {
            setIsLoading(false);
          });
        }
      }
    }
  };

  // Trigger the file input prompt
  const triggerFileInput = () => {
    if (fileInput.current) fileInput.current.click();
  };

  // Remove the file, also set the input value to empty to allow it to be uploaded again
  const handleRemove = () => {
    setFileError(undefined);
    setFileValue(undefined);
    setPreview(undefined);
    if (fileInput.current) fileInput.current.value = '';
    onChange?.(null);
    onRemove?.();
  };

  const hasValue = value || fileValue;
  const fileName = fileValue?.name || preview?.split('/').pop();
  const fileSize = (fileValue?.size || 0) / 1024 / 1024;
  const isFileImage = ['jpg', 'jpeg', 'png'].includes(
    fileName?.split('.').pop()?.toLowerCase() || ''
  );

  return (
    <div
      className={cn(
        'flex flex-col gap-6 items-center p-8 border-dashed border-2 border-fog rounded-[10px] transition-all',
        isLoading && 'pointer-events-none',
        fileError && 'border-grapefruit'
      )}
    >
      <input
        type="file"
        className="hidden"
        ref={fileInput}
        accept={accept}
        onChange={handleChange}
      />

      {!hasValue && (
        <SmallButton onClick={triggerFileInput}>
          <UploadSimple /> Upload file
        </SmallButton>
      )}

      {hasValue && (
        <div className={'flex flex-col items-center gap-6 text-center'}>
          <div className="relative">
            <div className={cn(' transition-all', isLoading && 'opacity-5')}>
              {isFileImage ? (
                <img
                  src={preview}
                  alt="File Preview"
                  className="w-24 h-24 object-contain border border-fog rounded-md overflow-hidden"
                  onClick={triggerFileInput}
                />
              ) : (
                <File fontSize={48} />
              )}
            </div>
            {
              <SmallSpinner
                className={cn(
                  'absolute inset-0 pointer-events-none transition-all',
                  isLoading ? 'opacity-100' : 'opacity-0'
                )}
              />
            }
          </div>

          <p className="bits-text-caption break-all">
            {fileName} {!!fileSize && `(${fileSize.toFixed(2)} MB)`}
          </p>

          <div className="flex gap-2">
            <SmallButton variant="outline" onClick={triggerFileInput}>
              <ArrowsClockwise /> Change
            </SmallButton>
            <SmallButton variant="destructive-outline" onClick={handleRemove}>
              <TrashSimple /> Remove
            </SmallButton>
          </div>
        </div>
      )}

      <p
        className={cn(
          'text-pretty bits-text-caption text-smoke text-center',
          fileError && 'text-grapefruit'
        )}
      >
        {fileError || description}
      </p>
    </div>
  );
};
