import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import common from '../public/locales/en/common.json';
import login from '../public/locales/en/login.json';
import onboarding from '../public/locales/en/onboarding.json';
import questionnaire from '../public/locales/en/questionnaire.json';
import reports from '../public/locales/en/reports.json';
import workflows from '../public/locales/en/workflows.json';

export const resources = {
  en: {
    common,
    login,
    workflows,
    onboarding,
    questionnaire,
    reports,
  },
};

i18n
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    partialBundledLanguages: true,
    resources,
    defaultNS: 'common',
    ns: [
      // Local namespaces
      'common',
      'workflows',
      'onboarding',
      'questionnaire',
      'reports',
      'login',

      // Backend namespaces
      'shared',
      'kyb-questionnaire',
      'kyc-questionnaire',
    ],
    backend: {
      loadPath: '/api/locales/{{lng}}/{{ns}}',
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .then();

export default i18n;
