import React, { useEffect, useRef, useState } from 'react';
import { SliderProps } from '@radix-ui/react-slider';

import { Label } from '../label';
import { Slider } from '../slider';
import { InputErrorAndHint } from './input-error-and-hint';

export const FormSlider = ({
  label,
  error,
  hint,
  value: defaultValue,
  transformDisplayValue = (value) => value,
  min = 0,
  max = 100,
  ...props
}: {
  label: string;
  error?: string;
  hint?: string;
  value?: number;
  transformDisplayValue?: (value: number) => string | number;
  onChange?: (e: { target: { name?: string; value: number } }) => void;
} & Omit<SliderProps, 'value'>) => {
  const [value, setValue] = useState(defaultValue || 0);
  const textInput = useRef<HTMLInputElement>(null);
  const sliderInput = useRef<HTMLInputElement>(null);

  const handleChangeValue = (
    value: number | string,
    doNotTransform?: boolean
  ) => {
    const val = Math.min(max, Math.max(min, parseInt(String(value))));

    props.onChange?.({
      target: {
        name: props.name,
        value: val,
      },
    });

    if (textInput.current && !doNotTransform)
      textInput.current.value = transformDisplayValue(val).toString();
    setValue(val);
  };

  useEffect(() => {
    if (textInput.current)
      textInput.current.value = transformDisplayValue(value).toString();
  }, []);

  return (
    <Label>
      <p className="bits-text-body-2 mb-2">{label}</p>
      <div className="flex gap-4">
        <Slider
          {...props}
          value={[value]}
          ref={sliderInput}
          min={min}
          max={max}
          onValueChange={([value]) => {
            handleChangeValue(value || 0);
          }}
        />
        <input
          ref={textInput}
          aria-hidden
          type="text"
          className="p-0 border border-fog text-center h-[40px] w-[48px] rounded-md"
          defaultValue={value}
          onChange={(e) => {
            handleChangeValue(e.target.value, true);
          }}
          onBlur={(e) => {
            handleChangeValue(e.target.value);
          }}
        />
      </div>
      <InputErrorAndHint hint={hint} error={error} />
    </Label>
  );
};
