import React from 'react';
import {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
} from 'country-flag-icons/react/1x1';

const callCodes: Record<
  string,
  {
    callCode: string;
    countryCode: string;
    label: string;
    icon: React.ReactElement | null;
  }
> = {
  'AU+61': {
    callCode: '61',
    countryCode: 'AU',
    icon: <AU />,
    label: '(+61) Australia',
  },
  'AT+43': {
    callCode: '43',
    countryCode: 'AT',
    icon: <AT />,
    label: '(+43) Austria',
  },
  'AZ+994': {
    callCode: '994',
    countryCode: 'AZ',
    icon: <AZ />,
    label: '(+994) Azerbaijan',
  },
  'AL+355': {
    callCode: '355',
    countryCode: 'AL',
    icon: <AL />,
    label: '(+355) Albania',
  },
  'DZ+213': {
    callCode: '213',
    countryCode: 'DZ',
    icon: <DZ />,
    label: '(+213) Algeria',
  },
  'AS+1684': {
    callCode: '1684',
    countryCode: 'AS',
    icon: <AS />,
    label: '(+1684) American Samoa',
  },
  'AI+1264': {
    callCode: '1264',
    countryCode: 'AI',
    icon: <AI />,
    label: '(+1264) Anguilla',
  },
  'AO+244': {
    callCode: '244',
    countryCode: 'AO',
    icon: <AO />,
    label: '(+244) Angola',
  },
  'AD+376': {
    callCode: '376',
    countryCode: 'AD',
    icon: <AD />,
    label: '(+376) Andorra',
  },
  'AQ+672': {
    callCode: '672',
    countryCode: 'AQ',
    icon: <AQ />,
    label: '(+672) Antarctica',
  },
  'AG+1268': {
    callCode: '1268',
    countryCode: 'AG',
    icon: <AG />,
    label: '(+1268) Antigua and Barbuda',
  },
  'AN+599': {
    callCode: '599',
    countryCode: 'AN',
    icon: null, // todo: add custom flag
    label: '(+599) Netherlands Antilles',
  },
  'AE+971': {
    callCode: '971',
    countryCode: 'AE',
    icon: <AE />,
    label: '(+971) United Arab Emirates',
  },
  'AR+54': {
    callCode: '54',
    countryCode: 'AR',
    icon: <AR />,
    label: '(+54) Argentina',
  },
  'AM+374': {
    callCode: '374',
    countryCode: 'AM',
    icon: <AM />,
    label: '(+374) Armenia',
  },
  'AW+297': {
    callCode: '297',
    countryCode: 'AW',
    icon: <AW />,
    label: '(+297) Aruba',
  },
  'AW+5998': {
    callCode: '5998',
    countryCode: 'AW',
    icon: <AW />,
    label: '(+5998) Aruba',
  },
  'AF+93': {
    callCode: '93',
    countryCode: 'AF',
    icon: <AF />,
    label: '(+93) Afghanistan',
  },
  'BS+1242': {
    callCode: '1242',
    countryCode: 'BS',
    icon: <BS />,
    label: '(+1242) Bahamas',
  },
  'BD+880': {
    callCode: '880',
    countryCode: 'BD',
    icon: <BD />,
    label: '(+880) Bangladesh',
  },
  'BB+1246': {
    callCode: '1246',
    countryCode: 'BB',
    icon: <BB />,
    label: '(+1246) Barbados',
  },
  'BH+973': {
    callCode: '973',
    countryCode: 'BH',
    icon: <BH />,
    label: '(+973) Bahrain',
  },
  'BY+375': {
    callCode: '375',
    countryCode: 'BY',
    icon: <BY />,
    label: '(+375) Belarus',
  },
  'BZ+501': {
    callCode: '501',
    countryCode: 'BZ',
    icon: <BZ />,
    label: '(+501) Belize',
  },
  'BE+32': {
    callCode: '32',
    countryCode: 'BE',
    icon: <BE />,
    label: '(+32) Belgium',
  },
  'BJ+229': {
    callCode: '229',
    countryCode: 'BJ',
    icon: <BJ />,
    label: '(+229) Benin',
  },
  'BM+1441': {
    callCode: '1441',
    countryCode: 'BM',
    icon: <BM />,
    label: '(+1441) Bermuda',
  },
  'BG+359': {
    callCode: '359',
    countryCode: 'BG',
    icon: <BG />,
    label: '(+359) Bulgaria',
  },
  'BO+591': {
    callCode: '591',
    countryCode: 'BO',
    icon: <BO />,
    label: '(+591) Bolivia',
  },
  'BA+387': {
    callCode: '387',
    countryCode: 'BA',
    icon: <BA />,
    label: '(+387) Bosnia and Herzegovina',
  },
  'BW+267': {
    callCode: '267',
    countryCode: 'BW',
    icon: <BW />,
    label: '(+267) Botswana',
  },
  'BR+55': {
    callCode: '55',
    countryCode: 'BR',
    icon: <BR />,
    label: '(+55) Brazil',
  },
  'IO+246': {
    callCode: '246',
    countryCode: 'IO',
    icon: <IO />,
    label: '(+246) British Indian Ocean Territory',
  },
  'BN+673': {
    callCode: '673',
    countryCode: 'BN',
    icon: <BN />,
    label: '(+673) Brunei Darussalam',
  },
  'BF+226': {
    callCode: '226',
    countryCode: 'BF',
    icon: <BF />,
    label: '(+226) Burkina Faso',
  },
  'BI+257': {
    callCode: '257',
    countryCode: 'BI',
    icon: <BI />,
    label: '(+257) Burundi',
  },
  'BT+975': {
    callCode: '975',
    countryCode: 'BT',
    icon: <BT />,
    label: '(+975) Bhutan',
  },
  'VU+678': {
    callCode: '678',
    countryCode: 'VU',
    icon: <VU />,
    label: '(+678) Vanuatu',
  },
  'VA+3906698': {
    callCode: '3906698',
    countryCode: 'VA',
    icon: <VA />,
    label: '(+3906698) Holy See (Vatican City State)',
  },
  'GB+44': {
    callCode: '44',
    countryCode: 'GB',
    icon: <GB />,
    label: '(+44) United Kingdom',
  },
  'HU+36': {
    callCode: '36',
    countryCode: 'HU',
    icon: <HU />,
    label: '(+36) Hungary',
  },
  'VE+58': {
    callCode: '58',
    countryCode: 'VE',
    icon: <VE />,
    label: '(+58) Venezuela',
  },
  'VG+1284': {
    callCode: '1284',
    countryCode: 'VG',
    icon: <VG />,
    label: '(+1284) Virgin Islands British',
  },
  'VI+1340': {
    callCode: '1340',
    countryCode: 'VI',
    icon: <VI />,
    label: '(+1340) Virgin Islands US',
  },
  'TL+670': {
    callCode: '670',
    countryCode: 'TL',
    icon: <TL />,
    label: '(+670) Timor-Leste (East Timor)',
  },
  'VN+84': {
    callCode: '84',
    countryCode: 'VN',
    icon: <VN />,
    label: '(+84) Vietnam',
  },
  'GA+241': {
    callCode: '241',
    countryCode: 'GA',
    icon: <GA />,
    label: '(+241) Gabon',
  },
  'HT+509': {
    callCode: '509',
    countryCode: 'HT',
    icon: <HT />,
    label: '(+509) Haiti',
  },
  'GY+592': {
    callCode: '592',
    countryCode: 'GY',
    icon: <GY />,
    label: '(+592) Guyana',
  },
  'GM+220': {
    callCode: '220',
    countryCode: 'GM',
    icon: <GM />,
    label: '(+220) Gambia',
  },
  'GH+233': {
    callCode: '233',
    countryCode: 'GH',
    icon: <GH />,
    label: '(+233) Ghana',
  },
  'GP+590': {
    callCode: '590',
    countryCode: 'GP',
    icon: <GP />,
    label: '(+590) Guadeloupe',
  },
  'GT+502': {
    callCode: '502',
    countryCode: 'GT',
    icon: <GT />,
    label: '(+502) Guatemala',
  },
  'GN+224': {
    callCode: '224',
    countryCode: 'GN',
    icon: <GN />,
    label: '(+224) Guinea',
  },
  'GW+245': {
    callCode: '245',
    countryCode: 'GW',
    icon: <GW />,
    label: '(+245) Guinea-Bissau',
  },
  'DE+49': {
    callCode: '49',
    countryCode: 'DE',
    icon: <DE />,
    label: '(+49) Germany',
  },
  'GI+350': {
    callCode: '350',
    countryCode: 'GI',
    icon: <GI />,
    label: '(+350) Gibraltar',
  },
  'HN+504': {
    callCode: '504',
    countryCode: 'HN',
    icon: <HN />,
    label: '(+504) Honduras',
  },
  'HK+852': {
    callCode: '852',
    countryCode: 'HK',
    icon: <HK />,
    label: '(+852) Hong Kong (Special Administrative Region of China)',
  },
  'GD+1473': {
    callCode: '1473',
    countryCode: 'GD',
    icon: <GD />,
    label: '(+1473) Grenada',
  },
  'GL+299': {
    callCode: '299',
    countryCode: 'GL',
    icon: <GL />,
    label: '(+299) Greenland',
  },
  'GR+30': {
    callCode: '30',
    countryCode: 'GR',
    icon: <GR />,
    label: '(+30) Greece',
  },
  'GE+995': {
    callCode: '995',
    countryCode: 'GE',
    icon: <GE />,
    label: '(+995) Georgia',
  },
  'GU+1671': {
    callCode: '1671',
    countryCode: 'GU',
    icon: <GU />,
    label: '(+1671) Guam',
  },
  'DK+45': {
    callCode: '45',
    countryCode: 'DK',
    icon: <DK />,
    label: '(+45) Denmark',
  },
  'CD+243': {
    callCode: '243',
    countryCode: 'CD',
    icon: <CD />,
    label: '(+243) Democratic Republic of the Congo',
  },
  'DJ+253': {
    callCode: '253',
    countryCode: 'DJ',
    icon: <DJ />,
    label: '(+253) Djibouti',
  },
  'DM+1767': {
    callCode: '1767',
    countryCode: 'DM',
    icon: <DM />,
    label: '(+1767) Dominica',
  },
  'DO+1809': {
    callCode: '1809',
    countryCode: 'DO',
    icon: <DO />,
    label: '(+1809) Dominican Republic',
  },
  'DO+1829': {
    callCode: '1829',
    countryCode: 'DO',
    icon: <DO />,
    label: '(+1829) Dominican Republic',
  },
  'DO+1849': {
    callCode: '1849',
    countryCode: 'DO',
    icon: <DO />,
    label: '(+1849) Dominican Republic',
  },
  'EG+20': {
    callCode: '20',
    countryCode: 'EG',
    icon: <EG />,
    label: '(+20) Egypt',
  },
  'ZM+260': {
    callCode: '260',
    countryCode: 'ZM',
    icon: <ZM />,
    label: '(+260) Zambia',
  },
  'EH+212': {
    callCode: '212',
    countryCode: 'EH',
    icon: <EH />,
    label: '(+212) Western Sahara',
  },
  'ZW+263': {
    callCode: '263',
    countryCode: 'ZW',
    icon: <ZW />,
    label: '(+263) Zimbabwe',
  },
  'IL+972': {
    callCode: '972',
    countryCode: 'IL',
    icon: <IL />,
    label: '(+972) Israel',
  },
  'IN+91': {
    callCode: '91',
    countryCode: 'IN',
    icon: <IN />,
    label: '(+91) India',
  },
  'ID+62': {
    callCode: '62',
    countryCode: 'ID',
    icon: <ID />,
    label: '(+62) Indonesia',
  },
  'JO+962': {
    callCode: '962',
    countryCode: 'JO',
    icon: <JO />,
    label: '(+962) Jordan',
  },
  'IQ+964': {
    callCode: '964',
    countryCode: 'IQ',
    icon: <IQ />,
    label: '(+964) Iraq',
  },
  'IR+98': {
    callCode: '98',
    countryCode: 'IR',
    icon: <IR />,
    label: '(+98) Iran (Islamic Republic of)',
  },
  'IE+353': {
    callCode: '353',
    countryCode: 'IE',
    icon: <IE />,
    label: '(+353) Ireland',
  },
  'IS+354': {
    callCode: '354',
    countryCode: 'IS',
    icon: <IS />,
    label: '(+354) Iceland',
  },
  'ES+34': {
    callCode: '34',
    countryCode: 'ES',
    icon: <ES />,
    label: '(+34) Spain',
  },
  'IT+39': {
    callCode: '39',
    countryCode: 'IT',
    icon: <IT />,
    label: '(+39) Italy',
  },
  'YE+967': {
    callCode: '967',
    countryCode: 'YE',
    icon: <YE />,
    label: '(+967) Yemen',
  },
  'KZ+7': {
    callCode: '7',
    countryCode: 'KZ',
    icon: <KZ />,
    label: '(+7) Kazakhstan',
  },
  'KY+1345': {
    callCode: '1345',
    countryCode: 'KY',
    icon: <KY />,
    label: '(+1345) Cayman Islands',
  },
  'KH+855': {
    callCode: '855',
    countryCode: 'KH',
    icon: <KH />,
    label: '(+855) Cambodia',
  },
  'CM+237': {
    callCode: '237',
    countryCode: 'CM',
    icon: <CM />,
    label: '(+237) Cameroon',
  },
  'CA+1': {
    callCode: '1',
    countryCode: 'CA',
    icon: <CA />,
    label: '(+1) Canada',
  },
  'QA+974': {
    callCode: '974',
    countryCode: 'QA',
    icon: <QA />,
    label: '(+974) Qatar',
  },
  'KE+254': {
    callCode: '254',
    countryCode: 'KE',
    icon: <KE />,
    label: '(+254) Kenya',
  },
  'CY+357': {
    callCode: '357',
    countryCode: 'CY',
    icon: <CY />,
    label: '(+357) Cyprus',
  },
  'KI+686': {
    callCode: '686',
    countryCode: 'KI',
    icon: <KI />,
    label: '(+686) Kiribati',
  },
  'CN+86': {
    callCode: '86',
    countryCode: 'CN',
    icon: <CN />,
    label: '(+86) China',
  },
  'CC+672': {
    callCode: '672',
    countryCode: 'CC',
    icon: <CC />,
    label: '(+672) Cocos (Keeling) Islands',
  },
  'CC+6189162': {
    callCode: '6189162',
    countryCode: 'CC',
    icon: <CC />,
    label: '(+6189162) Cocos (Keeling) Islands',
  },
  'CO+57': {
    callCode: '57',
    countryCode: 'CO',
    icon: <CO />,
    label: '(+57) Colombia',
  },
  'KM+269': {
    callCode: '269',
    countryCode: 'KM',
    icon: <KM />,
    label: '(+269) Comoros',
  },
  'CG+242': {
    callCode: '242',
    countryCode: 'CG',
    icon: <CG />,
    label: '(+242) Congo',
  },
  'KP+850': {
    callCode: '850',
    countryCode: 'KP',
    icon: <KP />,
    label: '(+850) Democratic People`s Republic of Korea',
  },
  'KR+82': {
    callCode: '82',
    countryCode: 'KR',
    icon: <KR />,
    label: '(+82) Republic of Korea',
  },
  'CR+506': {
    callCode: '506',
    countryCode: 'CR',
    icon: <CR />,
    label: '(+506) Costa Rica',
  },
  'CI+225': {
    callCode: '225',
    countryCode: 'CI',
    icon: <CI />,
    label: '(+225) Cote d`Ivoire',
  },
  'CU+53': {
    callCode: '53',
    countryCode: 'CU',
    icon: <CU />,
    label: '(+53) Cuba',
  },
  'KW+965': {
    callCode: '965',
    countryCode: 'KW',
    icon: <KW />,
    label: '(+965) Kuwait',
  },
  'KG+996': {
    callCode: '996',
    countryCode: 'KG',
    icon: <KG />,
    label: '(+996) Kyrgyzstan',
  },
  'LA+856': {
    callCode: '856',
    countryCode: 'LA',
    icon: <LA />,
    label: '(+856) Lao People`s Democratic Republic',
  },
  'LV+371': {
    callCode: '371',
    countryCode: 'LV',
    icon: <LV />,
    label: '(+371) Latvia',
  },
  'LS+266': {
    callCode: '266',
    countryCode: 'LS',
    icon: <LS />,
    label: '(+266) Lesotho',
  },
  'LR+231': {
    callCode: '231',
    countryCode: 'LR',
    icon: <LR />,
    label: '(+231) Liberia',
  },
  'LB+961': {
    callCode: '961',
    countryCode: 'LB',
    icon: <LB />,
    label: '(+961) Lebanon',
  },
  'LY+218': {
    callCode: '218',
    countryCode: 'LY',
    icon: <LY />,
    label: '(+218) Libyan Arab Jamahiriya',
  },
  'LT+370': {
    callCode: '370',
    countryCode: 'LT',
    icon: <LT />,
    label: '(+370) Lithuania',
  },
  'LI+423': {
    callCode: '423',
    countryCode: 'LI',
    icon: <LI />,
    label: '(+423) Liechtenstein',
  },
  'LU+352': {
    callCode: '352',
    countryCode: 'LU',
    icon: <LU />,
    label: '(+352) Luxembourg',
  },
  'MU+230': {
    callCode: '230',
    countryCode: 'MU',
    icon: <MU />,
    label: '(+230) Mauritius',
  },
  'MR+222': {
    callCode: '222',
    countryCode: 'MR',
    icon: <MR />,
    label: '(+222) Mauritania',
  },
  'MG+261': {
    callCode: '261',
    countryCode: 'MG',
    icon: <MG />,
    label: '(+261) Madagascar',
  },
  'YT+262269': {
    callCode: '262269',
    countryCode: 'YT',
    icon: <YT />,
    label: '(+262269) Mayotte',
  },
  'YT+262639': {
    callCode: '262639',
    countryCode: 'YT',
    icon: <YT />,
    label: '(+262639) Mayotte',
  },
  'MO+853': {
    callCode: '853',
    countryCode: 'MO',
    icon: <MO />,
    label: '(+853) Macau (Special Administrative Region of China)',
  },
  'MK+389': {
    callCode: '389',
    countryCode: 'MK',
    icon: <MK />,
    label: '(+389) North Macedonia (Republic of North Macedonia)',
  },
  'MW+265': {
    callCode: '265',
    countryCode: 'MW',
    icon: <MW />,
    label: '(+265) Malawi',
  },
  'MY+60': {
    callCode: '60',
    countryCode: 'MY',
    icon: <MY />,
    label: '(+60) Malaysia',
  },
  'ML+223': {
    callCode: '223',
    countryCode: 'ML',
    icon: <ML />,
    label: '(+223) Mali',
  },
  'MV+960': {
    callCode: '960',
    countryCode: 'MV',
    icon: <MV />,
    label: '(+960) Maldives',
  },
  'MT+356': {
    callCode: '356',
    countryCode: 'MT',
    icon: <MT />,
    label: '(+356) Malta',
  },
  'MP+1670': {
    callCode: '1670',
    countryCode: 'MP',
    icon: <MP />,
    label: '(+1670) Northern Mariana Islands',
  },
  'MA+212': {
    callCode: '212',
    countryCode: 'MA',
    icon: <MA />,
    label: '(+212) Morocco',
  },
  'MQ+596': {
    callCode: '596',
    countryCode: 'MQ',
    icon: <MQ />,
    label: '(+596) Martinique',
  },
  'MH+692': {
    callCode: '692',
    countryCode: 'MH',
    icon: <MH />,
    label: '(+692) Marshall Islands',
  },
  'MX+52': {
    callCode: '52',
    countryCode: 'MX',
    icon: <MX />,
    label: '(+52) Mexico',
  },
  'FM+691': {
    callCode: '691',
    countryCode: 'FM',
    icon: <FM />,
    label: '(+691) Micronesia (Federated States of)',
  },
  'MZ+258': {
    callCode: '258',
    countryCode: 'MZ',
    icon: <MZ />,
    label: '(+258) Mozambique',
  },
  'MD+373': {
    callCode: '373',
    countryCode: 'MD',
    icon: <MD />,
    label: '(+373) Moldova (Republic of)',
  },
  'MC+377': {
    callCode: '377',
    countryCode: 'MC',
    icon: <MC />,
    label: '(+377) Monaco',
  },
  'MN+976': {
    callCode: '976',
    countryCode: 'MN',
    icon: <MN />,
    label: '(+976) Mongolia',
  },
  'MS+1664': {
    callCode: '1664',
    countryCode: 'MS',
    icon: <MS />,
    label: '(+1664) Montserrat',
  },
  'MM+95': {
    callCode: '95',
    countryCode: 'MM',
    icon: <MM />,
    label: '(+95) Myanmar',
  },
  'NA+264': {
    callCode: '264',
    countryCode: 'NA',
    icon: <NA />,
    label: '(+264) Namibia',
  },
  'NR+674': {
    callCode: '674',
    countryCode: 'NR',
    icon: <NR />,
    label: '(+674) Nauru',
  },
  'NP+977': {
    callCode: '977',
    countryCode: 'NP',
    icon: <NP />,
    label: '(+977) Nepal',
  },
  'NE+227': {
    callCode: '227',
    countryCode: 'NE',
    icon: <NE />,
    label: '(+227) Niger',
  },
  'NG+234': {
    callCode: '234',
    countryCode: 'NG',
    icon: <NG />,
    label: '(+234) Nigeria',
  },
  'NL+31': {
    callCode: '31',
    countryCode: 'NL',
    icon: <NL />,
    label: '(+31) Netherlands',
  },
  'NI+505': {
    callCode: '505',
    countryCode: 'NI',
    icon: <NI />,
    label: '(+505) Nicaragua',
  },
  'NU+683': {
    callCode: '683',
    countryCode: 'NU',
    icon: <NU />,
    label: '(+683) Niue',
  },
  'NZ+64': {
    callCode: '64',
    countryCode: 'NZ',
    icon: <NZ />,
    label: '(+64) New Zealand',
  },
  'NC+687': {
    callCode: '687',
    countryCode: 'NC',
    icon: <NC />,
    label: '(+687) New Caledonia',
  },
  'NO+47': {
    callCode: '47',
    countryCode: 'NO',
    icon: <NO />,
    label: '(+47) Norway',
  },
  'OM+968': {
    callCode: '968',
    countryCode: 'OM',
    icon: <OM />,
    label: '(+968) Oman',
  },
  'BV+47': {
    callCode: '47',
    countryCode: 'BV',
    icon: <BV />,
    label: '(+47) Bouvet Island',
  },
  'IM+441624': {
    callCode: '441624',
    countryCode: 'IM',
    icon: <IM />,
    label: '(+441624) Isle Of Man',
  },
  'NF+672': {
    callCode: '672',
    countryCode: 'NF',
    icon: <NF />,
    label: '(+672) Norfolk Island',
  },
  'PN+64': {
    callCode: '64',
    countryCode: 'PN',
    icon: <PN />,
    label: '(+64) Pitcairn',
  },
  'CX+6189164': {
    callCode: '6189164',
    countryCode: 'CX',
    icon: <CX />,
    label: '(+6189164) Christmas Island',
  },
  'SH+290': {
    callCode: '290',
    countryCode: 'SH',
    icon: <SH />,
    label: '(+290) Saint Helena',
  },
  'WF+681': {
    callCode: '681',
    countryCode: 'WF',
    icon: <WF />,
    label: '(+681) Wallis and Futuna Islands',
  },
  'HM+61': {
    callCode: '61',
    countryCode: 'HM',
    icon: <HM />,
    label: '(+61) Heard Island and McDonald Islands',
  },
  'CV+238': {
    callCode: '238',
    countryCode: 'CV',
    icon: <CV />,
    label: '(+238) Cape Verde',
  },
  'CK+682': {
    callCode: '682',
    countryCode: 'CK',
    icon: <CK />,
    label: '(+682) Cook Islands',
  },
  'WS+685': {
    callCode: '685',
    countryCode: 'WS',
    icon: <WS />,
    label: '(+685) Samoa',
  },
  'SJ+4779': {
    callCode: '4779',
    countryCode: 'SJ',
    icon: <SJ />,
    label: '(+4779) Svalbard and Jan Mayen Islands',
  },
  'TC+1649': {
    callCode: '1649',
    countryCode: 'TC',
    icon: <TC />,
    label: '(+1649) Turks and Caicos Islands',
  },
  'UM+1': {
    callCode: '1',
    countryCode: 'UM',
    icon: <UM />,
    label: '(+1) United States Minor Outlying Islands',
  },
  'PK+92': {
    callCode: '92',
    countryCode: 'PK',
    icon: <PK />,
    label: '(+92) Pakistan',
  },
  'PW+680': {
    callCode: '680',
    countryCode: 'PW',
    icon: <PW />,
    label: '(+680) Palau',
  },
  'PS+970': {
    callCode: '970',
    countryCode: 'PS',
    icon: <PS />,
    label: '(+970) Palestinian Territory (Occupied)',
  },
  'PA+507': {
    callCode: '507',
    countryCode: 'PA',
    icon: <PA />,
    label: '(+507) Panama',
  },
  'PG+675': {
    callCode: '675',
    countryCode: 'PG',
    icon: <PG />,
    label: '(+675) Papua New Guinea',
  },
  'PY+595': {
    callCode: '595',
    countryCode: 'PY',
    icon: <PY />,
    label: '(+595) Paraguay',
  },
  'PE+51': {
    callCode: '51',
    countryCode: 'PE',
    icon: <PE />,
    label: '(+51) Peru',
  },
  'PL+48': {
    callCode: '48',
    countryCode: 'PL',
    icon: <PL />,
    label: '(+48) Poland',
  },
  'PT+351': {
    callCode: '351',
    countryCode: 'PT',
    icon: <PT />,
    label: '(+351) Portugal',
  },
  'PR+1787': {
    callCode: '1787',
    countryCode: 'PR',
    icon: <PR />,
    label: '(+1787) Puerto Rico',
  },
  'PR+1939': {
    callCode: '1939',
    countryCode: 'PR',
    icon: <PR />,
    label: '(+1939) Puerto Rico',
  },
  'RE+262': {
    callCode: '262',
    countryCode: 'RE',
    icon: <RE />,
    label: '(+262) Reunion',
  },
  'RU+7': {
    callCode: '7',
    countryCode: 'RU',
    icon: <RU />,
    label: '(+7) Russian Federation',
  },
  'RW+250': {
    callCode: '250',
    countryCode: 'RW',
    icon: <RW />,
    label: '(+250) Rwanda',
  },
  'RO+40': {
    callCode: '40',
    countryCode: 'RO',
    icon: <RO />,
    label: '(+40) Romania',
  },
  'SV+503': {
    callCode: '503',
    countryCode: 'SV',
    icon: <SV />,
    label: '(+503) El Salvador',
  },
  'SM+378': {
    callCode: '378',
    countryCode: 'SM',
    icon: <SM />,
    label: '(+378) San Marino',
  },
  'ST+239': {
    callCode: '239',
    countryCode: 'ST',
    icon: <ST />,
    label: '(+239) Sao Tome and Principe',
  },
  'SA+966': {
    callCode: '966',
    countryCode: 'SA',
    icon: <SA />,
    label: '(+966) Saudi Arabia',
  },
  'SZ+268': {
    callCode: '268',
    countryCode: 'SZ',
    icon: <SZ />,
    label: '(+268) Swaziland',
  },
  'SC+248': {
    callCode: '248',
    countryCode: 'SC',
    icon: <SC />,
    label: '(+248) Seychelles',
  },
  'SN+221': {
    callCode: '221',
    countryCode: 'SN',
    icon: <SN />,
    label: '(+221) Senegal',
  },
  'PM+508': {
    callCode: '508',
    countryCode: 'PM',
    icon: <PM />,
    label: '(+508) Saint Pierre and Miquelon',
  },
  'VC+1784': {
    callCode: '1784',
    countryCode: 'VC',
    icon: <VC />,
    label: '(+1784) Saint Vincent and the Grenadines',
  },
  'KN+1869': {
    callCode: '1869',
    countryCode: 'KN',
    icon: <KN />,
    label: '(+1869) Saint Kitts and Nevis',
  },
  'LC+1758': {
    callCode: '1758',
    countryCode: 'LC',
    icon: <LC />,
    label: '(+1758) Saint Lucia',
  },
  'SG+65': {
    callCode: '65',
    countryCode: 'SG',
    icon: <SG />,
    label: '(+65) Singapore',
  },
  'SY+963': {
    callCode: '963',
    countryCode: 'SY',
    icon: <SY />,
    label: '(+963) Syrian Arab Republic',
  },
  'SK+421': {
    callCode: '421',
    countryCode: 'SK',
    icon: <SK />,
    label: '(+421) Slovakia',
  },
  'SI+386': {
    callCode: '386',
    countryCode: 'SI',
    icon: <SI />,
    label: '(+386) Slovenia',
  },
  'US+1': {
    callCode: '1',
    countryCode: 'US',
    icon: <US />,
    label: '(+1) United States',
  },
  'SB+677': {
    callCode: '677',
    countryCode: 'SB',
    icon: <SB />,
    label: '(+677) Solomon Islands',
  },
  'SO+252': {
    callCode: '252',
    countryCode: 'SO',
    icon: <SO />,
    label: '(+252) Somalia',
  },
  'SD+249': {
    callCode: '249',
    countryCode: 'SD',
    icon: <SD />,
    label: '(+249) Sudan',
  },
  'SR+597': {
    callCode: '597',
    countryCode: 'SR',
    icon: <SR />,
    label: '(+597) Suriname',
  },
  'SL+232': {
    callCode: '232',
    countryCode: 'SL',
    icon: <SL />,
    label: '(+232) Sierra Leone',
  },
  'TJ+992': {
    callCode: '992',
    countryCode: 'TJ',
    icon: <TJ />,
    label: '(+992) Tajikistan',
  },
  'TW+886': {
    callCode: '886',
    countryCode: 'TW',
    icon: <TW />,
    label: '(+886) Taiwan (Province of China)',
  },
  'TH+66': {
    callCode: '66',
    countryCode: 'TH',
    icon: <TH />,
    label: '(+66) Thailand',
  },
  'TZ+255': {
    callCode: '255',
    countryCode: 'TZ',
    icon: <TZ />,
    label: '(+255) Tanzania (United Republic of)',
  },
  'TG+228': {
    callCode: '228',
    countryCode: 'TG',
    icon: <TG />,
    label: '(+228) Togo',
  },
  'TK+690': {
    callCode: '690',
    countryCode: 'TK',
    icon: <TK />,
    label: '(+690) Tokelau',
  },
  'TO+676': {
    callCode: '676',
    countryCode: 'TO',
    icon: <TO />,
    label: '(+676) Tonga',
  },
  'TT+1868': {
    callCode: '1868',
    countryCode: 'TT',
    icon: <TT />,
    label: '(+1868) Trinidad and Tobago',
  },
  'TV+688': {
    callCode: '688',
    countryCode: 'TV',
    icon: <TV />,
    label: '(+688) Tuvalu',
  },
  'TN+216': {
    callCode: '216',
    countryCode: 'TN',
    icon: <TN />,
    label: '(+216) Tunisia',
  },
  'TM+993': {
    callCode: '993',
    countryCode: 'TM',
    icon: <TM />,
    label: '(+993) Turkmenistan',
  },
  'TR+90': {
    callCode: '90',
    countryCode: 'TR',
    icon: <TR />,
    label: '(+90) Turkey',
  },
  'UG+256': {
    callCode: '256',
    countryCode: 'UG',
    icon: <UG />,
    label: '(+256) Uganda',
  },
  'UZ+998': {
    callCode: '998',
    countryCode: 'UZ',
    icon: <UZ />,
    label: '(+998) Uzbekistan',
  },
  'UA+380': {
    callCode: '380',
    countryCode: 'UA',
    icon: <UA />,
    label: '(+380) Ukraine',
  },
  'UY+598': {
    callCode: '598',
    countryCode: 'UY',
    icon: <UY />,
    label: '(+598) Uruguay',
  },
  'FO+298': {
    callCode: '298',
    countryCode: 'FO',
    icon: <FO />,
    label: '(+298) Faroe Islands',
  },
  'FJ+679': {
    callCode: '679',
    countryCode: 'FJ',
    icon: <FJ />,
    label: '(+679) Fiji',
  },
  'PH+63': {
    callCode: '63',
    countryCode: 'PH',
    icon: <PH />,
    label: '(+63) Philippines',
  },
  'FI+358': {
    callCode: '358',
    countryCode: 'FI',
    icon: <FI />,
    label: '(+358) Finland',
  },
  'FK+500': {
    callCode: '500',
    countryCode: 'FK',
    icon: <FK />,
    label: '(+500) Falkland Islands (Malvinas)',
  },
  'FR+33': {
    callCode: '33',
    countryCode: 'FR',
    icon: <FR />,
    label: '(+33) France',
  },
  'GF+594': {
    callCode: '594',
    countryCode: 'GF',
    icon: <GF />,
    label: '(+594) French Guiana',
  },
  'PF+689': {
    callCode: '689',
    countryCode: 'PF',
    icon: <PF />,
    label: '(+689) French Polynesia',
  },
  'TF+1': {
    callCode: '1',
    countryCode: 'TF',
    icon: <TF />,
    label: '(+1) French Southern Territories',
  },
  'HR+385': {
    callCode: '385',
    countryCode: 'HR',
    icon: <HR />,
    label: '(+385) Croatia',
  },
  'CF+236': {
    callCode: '236',
    countryCode: 'CF',
    icon: <CF />,
    label: '(+236) Central African Republic',
  },
  'TD+235': {
    callCode: '235',
    countryCode: 'TD',
    icon: <TD />,
    label: '(+235) Chad',
  },
  'CZ+420': {
    callCode: '420',
    countryCode: 'CZ',
    icon: <CZ />,
    label: '(+420) Czechia',
  },
  'CL+56': {
    callCode: '56',
    countryCode: 'CL',
    icon: <CL />,
    label: '(+56) Chile',
  },
  'CH+41': {
    callCode: '41',
    countryCode: 'CH',
    icon: <CH />,
    label: '(+41) Switzerland',
  },
  'SE+46': {
    callCode: '46',
    countryCode: 'SE',
    icon: <SE />,
    label: '(+46) Sweden',
  },
  'LK+94': {
    callCode: '94',
    countryCode: 'LK',
    icon: <LK />,
    label: '(+94) Sri Lanka',
  },
  'EC+593': {
    callCode: '593',
    countryCode: 'EC',
    icon: <EC />,
    label: '(+593) Ecuador',
  },
  'GQ+240': {
    callCode: '240',
    countryCode: 'GQ',
    icon: <GQ />,
    label: '(+240) Equatorial Guinea',
  },
  'ER+291': {
    callCode: '291',
    countryCode: 'ER',
    icon: <ER />,
    label: '(+291) Eritrea',
  },
  'EE+372': {
    callCode: '372',
    countryCode: 'EE',
    icon: <EE />,
    label: '(+372) Estonia',
  },
  'ET+251': {
    callCode: '251',
    countryCode: 'ET',
    icon: <ET />,
    label: '(+251) Ethiopia',
  },
  'ZA+27': {
    callCode: '27',
    countryCode: 'ZA',
    icon: <ZA />,
    label: '(+27) South Africa',
  },
  'YU+38': {
    callCode: '38',
    countryCode: 'YU',
    icon: null,
    label: '(+38) Yugoslavia',
  },
  'GS+500': {
    callCode: '500',
    countryCode: 'GS',
    icon: <GS />,
    label: '(+500) South Georgia and The South Sandwich Islands',
  },
  'JM+1876': {
    callCode: '1876',
    countryCode: 'JM',
    icon: <JM />,
    label: '(+1876) Jamaica',
  },
  'JM+1658': {
    callCode: '1658',
    countryCode: 'JM',
    icon: <JM />,
    label: '(+1658) Jamaica',
  },
  'ME+382': {
    callCode: '382',
    countryCode: 'ME',
    icon: <ME />,
    label: '(+382) Montenegro',
  },
  'BL+590': {
    callCode: '590',
    countryCode: 'BL',
    icon: <BL />,
    label: '(+590) Saint Barthelemy',
  },
  'SX+1721': {
    callCode: '1721',
    countryCode: 'SX',
    icon: <SX />,
    label: '(+1721) Sint Maarten Dutch',
  },
  'RS+381': {
    callCode: '381',
    countryCode: 'RS',
    icon: <RS />,
    label: '(+381) Serbia',
  },
  'AX+35818': {
    callCode: '35818',
    countryCode: 'AX',
    icon: <AX />,
    label: '(+35818) Aland Islands',
  },
  'BQ+5993': {
    callCode: '5993',
    countryCode: 'BQ',
    icon: <BQ />,
    label: '(+5993) Bonaire, Sint Eustatius And Saba',
  },
  'BQ+5994': {
    callCode: '5994',
    countryCode: 'BQ',
    icon: <BQ />,
    label: '(+5994) Bonaire, Sint Eustatius And Saba',
  },
  'GG+441481': {
    callCode: '441481',
    countryCode: 'GG',
    icon: <GG />,
    label: '(+441481) Guernsey',
  },
  'JE+441534': {
    callCode: '441534',
    countryCode: 'JE',
    icon: <JE />,
    label: '(+441534) Jersey',
  },
  'CW+5999': {
    callCode: '5999',
    countryCode: 'CW',
    icon: <CW />,
    label: '(+5999) Curacao',
  },
  'MF+590': {
    callCode: '590',
    countryCode: 'MF',
    icon: <MF />,
    label: '(+590) Saint Martin French',
  },
  'SS+211': {
    callCode: '211',
    countryCode: 'SS',
    icon: <SS />,
    label: '(+211) South Sudan',
  },
  'JP+81': {
    callCode: '81',
    countryCode: 'JP',
    icon: <JP />,
    label: '(+81) Japan',
  },
  'XK+383': {
    callCode: '383',
    countryCode: 'XK',
    icon: <XK />,
    label: '(+383) Kosovo',
  },
};

export default callCodes;
