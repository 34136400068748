import React, { forwardRef } from 'react';
import { InputProps } from 'react-day-picker';

import { cn } from '../utils/cn';

export interface ThemedInputProps extends InputProps {
  label: string;
  error?: string;
  hint?: string;
  type: 'text' | 'password' | 'email' | 'number';
}

export const ThemedInput = forwardRef<HTMLInputElement, ThemedInputProps>(
  ({ label, hint, error, type, ...props }, ref) => {
    return (
      <div className="text-left">
        <label>
          <p
            className={cn(
              '[font-size:var(--inputs-labelFontSize)] [color:var(--inputs-labelTextColor)] mb-1',
              error && '[color:var(--inputs-errorTextColor)]'
            )}
          >
            {label}
          </p>
          <input
            type={type}
            className={cn(
              'transition-colors flex w-full px-3 py-2 focus-visible:outline-none disabled:cursor-not-allowed',
              '[font-size:var(--inputs-fontSize)]',
              '[color:var(--inputs-normal-textColor)] [background-color:var(--inputs-backgroundColor)] [border-color:var(--inputs-normal-borderColor)] [border-width:var(--inputs-borderWidth)] [border-radius:var(--inputs-cornerRadius)]',
              'focus:[border-color:var(--inputs-focus-borderColor)] focus:[color:var(--inputs-focus-textColor)]',
              'placeholder-[var(--inputs-placeholderColor)]',
              error &&
                '[border-color:var(--inputs-error-borderColor)] [color:var(--inputs-error-textColor)]',
              '[box-shadow:var(--inputs-shadow)]'
            )}
            ref={ref}
            {...props}
          />
        </label>
        {hint && !error && (
          <p className="[font-size:var(--inputs-hintAndErrorFontSize)] [color:var(--inputs-hintTextColor)] mt-1">
            {hint}
          </p>
        )}
        {error && (
          <p className="[font-size:var(--inputs-hintAndErrorFontSize)] [color:var(--inputs-errorTextColor)] mt-1">
            {error}
          </p>
        )}
      </div>
    );
  }
);

ThemedInput.displayName = 'ThemedInput';
