import React from 'react';
import { Slot } from '@radix-ui/react-slot';

import { ButtonProps, buttonVariants } from './base-button';
import { cn } from './utils/cn';

export interface LargeButtonProps extends ButtonProps {}

const buttonClass = 'bits-text-button-1 rounded-md h-11 px-6 py-2';

export const LargeButton = React.forwardRef<
  HTMLButtonElement,
  LargeButtonProps
>(
  (
    { asChild, children, className, variant, icon: Icon, iconProps, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonClass, buttonVariants({ variant, className }))}
        ref={ref}
        {...props}
      >
        {Icon ? (
          <Icon
            {...iconProps}
            className={cn('h-4 w-4', iconProps?.className)}
          />
        ) : null}
        {children}
      </Comp>
    );
  }
);

LargeButton.displayName = 'LargeButton';
