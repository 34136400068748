import React, { HTMLProps } from 'react';

import { cn } from '../utils/cn';

export interface ThemedHRProps extends HTMLProps<HTMLHRElement> {}

export const ThemedHR = (props: ThemedHRProps) => {
  return (
    <hr
      className={cn(
        '[border-color:var(--general-dividerColor)]',
        props.className
      )}
      {...props}
    />
  );
};
