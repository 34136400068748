'use client';

import * as React from 'react';
import { X } from '@phosphor-icons/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { RoundButton } from './round-button';
import { cn } from './utils/cn';

const Dialog = ({ children, ...props }: DialogPrimitive.DialogProps) => (
  <DialogPrimitive.Root {...props}>{children}</DialogPrimitive.Root>
);

const DialogTrigger = DialogPrimitive.Trigger;

const DialogClose = DialogPrimitive.Close;

const DialogPortal = DialogPrimitive.DialogPortal;

interface DialogContentProps {
  variant?: 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '3xl';
}

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps &
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, variant = 'sm', ...props }, ref) => (
  <DialogPortal>
    <DialogPrimitive.Overlay className="fixed flex overflow-auto p-8 inset-0 bg-ink/30 backdrop-blur-sm data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:animate-in data-[state=open]:fade-in">
      <DialogPrimitive.Content
        ref={ref}
        {...props}
        className={cn(
          `relative m-auto grid w-full gap-4 rounded-b-lg bg-background 
            p-6 shadow-[-10px_0px_54px_0px_rgba(0,0,0,0.05)] animate-in 
            slide-in-from-bottom-10 fade-in-0 ease-out
            sm:rounded-lg`,
          variant === 'xs' && 'max-w-md',
          variant === 'sm' && 'max-w-lg',
          variant === 'lg' && 'max-w-2xl',
          variant === 'xl' && 'max-w-3xl',
          variant === '2xl' && 'max-w-4xl',
          variant === '3xl' && 'max-w-4xl',
          className
        )}
      >
        {children}
        <DialogPrimitive.Close
          asChild
          className="absolute right-4 top-5 rounded-full opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none"
        >
          <RoundButton icon={X} />
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DialogPrimitive.Overlay>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col mt-1 space-y-1.5 text-center sm:text-left',
      className
    )}
    {...props}
  />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className
    )}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('bits-text-h2', className)}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
};
