import React, { HTMLProps } from 'react';
import { Slot } from '@radix-ui/react-slot';

import { cn } from '../utils/cn';

export interface ThemedHeadingProps
  extends Omit<HTMLProps<HTMLHeadingElement>, 'ref'> {
  asChild?: boolean;
}

export const ThemedHeading = ({
  asChild = false,
  className,
  ...props
}: ThemedHeadingProps) => {
  const Comp = asChild ? Slot : 'h2';
  return (
    <Comp
      className={cn(
        '[color:var(--typography-title-textColor)] [font-weight:var(--typography-title-fontWeight)] [font-size:var(--typography-title-fontSize)] [letter-spacing:var(--typography-title-letterSpacing)] [text-transform:var(--typography-title-uppercase)] leading-tight text-pretty',
        className
      )}
      {...props}
    />
  );
};
