'use client';

import * as React from 'react';
import { useState } from 'react';
import { CaretUpDown, Check } from '@phosphor-icons/react';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { cn } from './utils/cn';

export type SearchableSelectProps = {
  items: { label: string; value: string }[];
  multiselect?: boolean;
  placeholder: string;
  emptyText: string;
  onChange?: (value: string[] | string) => void;
};

export function SearchableSelect({
  items,
  multiselect,
  placeholder,
  emptyText,
  onChange,
}: SearchableSelectProps) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<string[]>([]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          role="combobox"
          aria-expanded={open}
          className={cn(
            'transition-colors flex items-center justify-between min-h-11 text-left w-full color-ink rounded-md border border-input bg-transparent px-3 py-2 bits-text-body-1 placeholder:text-smoke focus-visible:outline-none focus-visible:border-ink',
            'disabled:cursor-not-allowed disabled:bg-fog disabled:text-smoke',
            open && 'border-ink'
          )}
        >
          {values.length
            ? values
                .map((val) => items.find((i) => i.value === val)?.label)
                .join(', ')
            : placeholder}
          <CaretUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="p-0 border-none">
        <Command>
          <CommandInput placeholder={placeholder} />
          <CommandEmpty>{emptyText}</CommandEmpty>
          <CommandGroup>
            {items.map((item) => (
              <CommandItem
                key={item.value}
                value={item.label}
                onSelect={() => {
                  if (multiselect) {
                    if (values.includes(item.value)) {
                      setValues(values.filter((value) => value !== item.value));
                    } else {
                      const newValues = [...values, item.value];
                      setValues(newValues);
                      onChange?.(newValues);
                    }
                  } else {
                    setValues([item.value]);
                    onChange?.(item.value);
                    setOpen(false);
                  }
                }}
              >
                <span
                  className={cn(
                    'flex items-center justify-center w-5 h-5 border-fog rounded-md shrink-0 mr-2',
                    multiselect && 'border bg-white'
                  )}
                >
                  <Check
                    weight="bold"
                    className={cn(
                      'h-4 w-4',
                      values.includes(item.value) ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                </span>
                {item.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
