import * as React from 'react';
import { CaretDown } from '@phosphor-icons/react';
import * as Popover from '@radix-ui/react-popover';
import {
  getCountries,
  parsePhoneNumber,
  PhoneNumber,
} from 'react-phone-number-input';

import { Input } from './input';
import { ScrollArea } from './scroll-area';
import { cn } from './utils/cn';
import callCodes from './utils/country-call-codes';

export type CountryCode = PhoneNumber['country'];

export type PhoneNumberInputProps = {
  value?: string;
  onChange?: (v: { countryCode: string | null; number: string }) => void;
  className?: string;
  defaultCountryCode?: CountryCode;
};

type ScrollFadeState = 'top' | 'bottom' | 'both' | 'none';

const findCallCodeForCountryCode = (country: string) => {
  const callCodeKey = Object.keys(callCodes).find(
    (c) => callCodes[c]?.countryCode === country
  );
  return callCodeKey ? callCodes[callCodeKey]?.callCode : null;
};

export const PhoneNumberInput = ({
  value,
  defaultCountryCode = 'SE',
  onChange,
  className,
}: PhoneNumberInputProps) => {
  const phoneNumber = value ? parsePhoneNumber(value) : undefined;
  const searchRef = React.useRef<HTMLInputElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [scrollFade, setScrollFade] = React.useState<ScrollFadeState>('top');

  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [country, setCountry] = React.useState<string>(
    phoneNumber?.country || defaultCountryCode
  );

  const [callCode, setCallCode] = React.useState(
    phoneNumber?.countryCallingCode ||
      findCallCodeForCountryCode(defaultCountryCode)
  );
  const [number, setNumber] = React.useState(phoneNumber?.nationalNumber ?? '');

  const callCodeKey = country + '+' + callCode;
  const icon = callCodeKey ? callCodes[callCodeKey]?.icon : null;
  const setPhoneNumber = React.useCallback(
    ({ cc, n }: { cc?: string; n?: string }) => {
      if (typeof cc !== 'undefined') {
        setCallCode(cc);
      }
      if (typeof n !== 'undefined') {
        setNumber(n);
      }
      if (onChange) {
        onChange({ countryCode: cc ?? callCode ?? '', number: n ?? number });
      }
    },
    [callCode, number]
  );

  useClickOutside(contentRef, () => {
    if (!open) {
      return;
    }
    setOpen(false);
    setSearch('');
  });

  React.useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    const scroll = scrollRef.current;

    const onScroll = () => {
      const y = scroll.scrollTop;
      const box = scroll.getBoundingClientRect();

      let newState = scrollFade;

      if (box.height >= scroll.scrollHeight) {
        newState = 'none';
      } else if (y === 0) {
        newState = 'top';
      } else if (y + box.height === scroll.scrollHeight) {
        newState = 'bottom';
      } else if (box.height < scroll.scrollHeight) {
        newState = 'both';
      }

      if (newState !== scrollFade) {
        setScrollFade(newState);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      onScroll();
    });

    scroll.addEventListener('scroll', onScroll);
    resizeObserver.observe(scroll);

    return () => {
      scroll.removeEventListener('scroll', onScroll);
      resizeObserver.disconnect();
    };
  }, [scrollFade, open]);

  const hits = Object.keys(callCodes).filter(
    (c) => callCodes[c]?.label.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Popover.Root>
      <div className="relative" ref={contentRef}>
        <div className={cn('flex gap-3', className)}>
          <Popover.Trigger asChild>
            <button
              type="button"
              className={cn(
                'flex gap-2 border-fog rounded-md border-[1px] items-center px-2 select-none transition-all',
                { 'rounded-bl-none': open }
              )}
              onClick={() => {
                setSearch('');
                setOpen(!open);
                searchRef.current?.focus();
              }}
            >
              <div className="w-4 h-4 overflow-hidden rounded-full">{icon}</div>
              <div>+{callCodes[callCodeKey]?.callCode}</div>
              <CaretDown className="mx-1 w-4 h-4" />
            </button>
          </Popover.Trigger>
          <Input
            value={number || ''}
            onChange={(e) => setPhoneNumber({ n: e.target.value })}
          />
        </div>
        <Popover.Content className="z-40" align="start" side="bottom">
          <div className="max-w-[300px] flex flex-col overflow-hidden border-fog border bg-white rounded-2xl rounded-tl-none shadow-soft">
            <div className="px-4">
              <input
                autoFocus
                ref={searchRef}
                placeholder="Search country"
                value={search}
                className="bits-text-body-2 h-10 focus:outline-none shrink-0 w-full"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <div className="h-[1px] w-full bg-fog shrink-0" />
            <ScrollArea
              className="p-1"
              viewPortProps={{ ref: scrollRef, className: 'max-h-40' }}
            >
              <div
                className={cn(
                  'transition-opacity opacity-0 pointer-events-none top-1 left-0 absolute w-full h-10 from-white to-transparent bg-gradient-to-b',
                  {
                    'opacity-100':
                      scrollFade === 'bottom' || scrollFade === 'both',
                  }
                )}
              />
              <div
                className={cn(
                  'transition-opacity opacity-0 pointer-events-none bottom-1 left-0 absolute w-full h-10 from-white to-transparent bg-gradient-to-t',
                  {
                    'opacity-100':
                      scrollFade === 'top' || scrollFade === 'both',
                  }
                )}
              />
              {hits.map((c) => {
                const cc = callCodes[c];
                if (!cc) return null;
                return (
                  <button
                    type="button"
                    key={c}
                    onClick={() => {
                      setPhoneNumber({ cc: cc.callCode });
                      setCountry(cc.countryCode);
                      setOpen(false);
                      setSearch('');
                    }}
                    className={cn(
                      'rounded-md text-left flex cursor-pointer gap-3 py-2 px-3 w-full items-center bg-transparent transition-colors hover:bg-sand-light',
                      {
                        'bg-sand-light': cc.countryCode === country,
                      }
                    )}
                  >
                    <div className="w-4 h-4 rounded-full overflow-hidden">
                      {cc.icon}
                    </div>
                    <p className="bits-text-body-2">{cc.label}</p>
                  </button>
                );
              })}
              {hits.length === 0 ? (
                <div className={cn('flex gap-3 py-2 px-3 w-full items-center')}>
                  <div className="w-4 h-4 rounded-full overflow-hidden"></div>
                  <p className="bits-text-body-2">No results found</p>
                </div>
              ) : null}
            </ScrollArea>
          </div>
        </Popover.Content>
      </div>
    </Popover.Root>
  );
};

const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void
) => {
  const handleClick = React.useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    },
    [ref, callback]
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);
};

export const parseCountryCode = (value: string): CountryCode | undefined =>
  getCountries().find((c) => c === value);
